<template>
<ul v-if="this.$attrs.all != true" :class="isSimple?'simple super-menu':'mega super-menu'">
    <li v-for="child in getCategory.hijos" class="dropdown-item">
        <router-link :to="{path: '/catalogo', query: {cat: child.id}}" v-slot="{ href }">
            <a :href="href">{{ child.name }}</a>
        </router-link>
    </li>
    <div class="image" v-if="getCategory.preview_image != null">
        <div class="overlay"></div>
        <img :src="getCategory.preview_image.path" alt="">
    </div>
</ul>
<ul v-else class="mega super-menu">
    <li v-for="category in categories" class="dropdown-item">
        <router-link :to="{path: '/catalogo', query: {cat: category.id}}" v-slot="{ href }">
            <a :href="href">{{ category.name }}</a>
        </router-link>
    </li>
</ul>
</template>

<script>
export default {
    data(){
        return {
            is_all: false
        }
    },
    porps: {
        id: {
            type: Number,
            required: false,
            default: 0
        },
        all: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        categories() {
            return this.$store.getters.getItemsByName('categorias');
        },
        getCategory() {
            return this.categories.find( obj => {
                if (this.$attrs.all)
                    return obj;
                if (obj.id === this.$attrs.id)
                    return obj;
            } )
        }
    },
    methods: {
        dropdownInit() {
            var megaMenu = document.querySelectorAll(".mega-drop-down");
            var megaMenuItem = document.querySelectorAll(".mega-drop-down > ul.super-menu > li");

            Array.from(megaMenu).forEach((el) => {
                el.addEventListener('mouseover', () => {
                var mega = el.querySelector('.super-menu');
                if(mega)
                    mega.classList.add('show');

                var image = el.querySelector('.image');

                if(image)
                    image.classList.add('show');
                });

                el.addEventListener('mouseout', () => {
                    var mega = el.querySelector('.super-menu');
                    if(mega)
                        mega.classList.remove('show');

                    var image = el.querySelector('.image');

                    if(mega.hasAttribute('style'))
                        mega.removeAttribute('style');

                    if(image)
                        image.classList.remove('show');
                });
            })
        },
        isSimple() {
            return this.getCategory.hijos.length <= 5;
        }
    },
    updated() {
        this.$nextTick(() => {
            this.dropdownInit();
        })
    },
    watch: {
        all: {
            handler: (newVal, oldVal) => {
                console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            },
            immediate: true
        },
    }
}
</script>
